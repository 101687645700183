.flex-block{
    display: flex;
}

.w-js{
    width: 540px;
}

@media screen and (max-width: 720px) {
    .flex-block{
        display: block;
    }
    .w-js{
        width: 334px !important;
    }
}